@import "_config/_variables";

// Background
$body-bg: $viewer-htone;

// Buttons
$btn-default-bg: transparent;
$pagination-bg: transparent;

$btn-default-bg-img-start: $secondary-color;
$btn-default-bg-img-stop: $secondary-color-ltone;

// Background topbar
$navbar-pf-bg-color: $panel-background;

// Background toolbar
$nav-pf-vertical-bg-color: $panel-background;
$nav-pf-vertical-bg-color-secondary: $panel-background;
$navbar-pf-vertical-bg-color: $panel-background;

// ---- Dropdowns
$dropdown-bg: $secondary-color-htone;
$dropdown-link-color: $main-color-contrast;
$dropdown-border: $panel-background;
$font-size-base: 14px;
$dropdown-link-hover-color: $main-color-contrast;
$dropdown-link-hover-bg: $main-color;

// ---- Navbar - My projects - Tools
$navbar-pf-height: 40px;
$navbar-height: 40px;
$navbar-pf-vertical-border-width: 0px;

// Tables
$table-bg-accent: $panel-mtone;
$table-border-color: $toolpanel-background;
$table-bg-hover: $panel-htone;
$table-border-hover: $panel-background;

$link-color: $main-color-bg-light;

// Cards
$card-pf-bg-color: $toolpanel-background;
$card-pf-border-color: $toolpanel-htone;

// Popovers
$popover-max-width: auto;

// Badge
$badge-bg: $main-color;

// Views
$list-pf-border-color: $toolpanel-mtone;
$list-pf-active-border-color: $toolpanel-mtone;
$list-pf-header-background-color: $toolpanel-ltone;
$list-pf-hover-background-color: $toolpanel-ltone;
$list-view-accented-border: $toolpanel-mtone;
$list-view-active-bg: $toolpanel-ltone;
$list-view-active-border: $toolpanel-mtone;
$list-view-divider: $contrast-h;
$list-view-hover-bg: $toolpanel-ltone;
$list-group-top-border: $contrast-h;
$list-group-border: $toolpanel-mtone;
$list-group-hover-bg: $toolpanel-ltone;
$list-group-top-border: transparent;

//Modal
$modal-content-bg: $toolpanel-mtone;
$modal-content-border-color: $toolpanel-mtone;
$close-color: $toolpanel-ltone;
$text-color: $toolpanel-contrast;
$modal-lg: 1000px;
$list-group-bg: $toolpanel-mtone;

// Input
$input-bg: $panel-background;
$input-color: $toolpanel-contrast;
$input-border: $toolpanel-htone;
$input-bg-disabled: $secondary-color-ltone;

$input-group-addon-bg: $toolpanel-htone;

//$alert-danger-border: $toolpanel-ltone;
$alert-danger-text: $toolpanel-contrast;

//$alert-warning-border: $toolpanel-ltone;
$alert-warning-text: $toolpanel-contrast;

$alert-info-border: $main-color;
$alert-info-text: $toolpanel-contrast;

//$alert-success-border: $toolpanel-ltone;
$alert-success-text: $toolpanel-contrast;

$brand-danger: $error-color;
$brand-warning: $warning-color;
$brand-success: $success-color;

// Label
$label-warning-bg: $warning-color;

$drawer-pf-top-vertical: 35px;

// Panel
$panel-bg: transparent;
$panel-default-heading-bg: $glass-panel-htone;
$panel-default-text: $main-color-contrast;
$panel-default-border: $main-color-contrast;
$panel-default-border: $toolpanel-background;

// Tabs
$nav-tabs-border-color: $toolpanel-background;
$nav-tabs-active-link-hover-bg: $panel-background;
$nav-tabs-active-link-hover-border-color: $toolpanel-background;

// Tooltip
$tooltip-bg: $main-color-vltone;

html:not(.layout-pf) {
  .nav-pf-vertical.collapsed {
    width: 200px;
    @media (max-width: 767px) {
      width: 0;
    }
    .list-group-item > a {
      width: 200px;
    }
  }
  #page-main.container-pf-nav-pf-vertical,
  .container-pf-nav-pf-vertical {
    @media (max-width: 767px) {
      margin-left: 0;
      width: 100% !important;
    }
  }
}
html {
  .nav-tabs {
    & > li > a {
      color: $shade-l;
      &:hover,
      &:focus {
        color: $main-color-contrast;
      }
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
        border-bottom-color: $glass-panel-background;
      }
    }
  }
}

.control-label {
  margin-bottom: 5px;
}

body .list-group-item-container {
  background: transparent;
}
.comment-details {
  display: none;
}

@import "sculpteo-colors";
$img-path: "~patternfly/dist/img/";
$font-path: "~patternfly/dist/fonts/";
$icon-font-path: "~patternfly/dist/fonts/";
@import "~patternfly/dist/sass/patternfly";

// This is needed for transitioning loading state to content
// in place as the elements need to be on position absolute
.container-fluid.container-cards-pf {
  position: relative;
}

.container-fluid .container-relative {
  position: relative;
  height: 100%;
}

svg.icon {
  width: 20px;
  height: 20px;
}

.p0 {
  padding-left: 0;
  padding-right: 0;
}

.drawer-pf {
  height: 95vh;
  top: 40px;
}

// The the modal header doesn't have a variable for the background-color
.modal-header {
  background-color: $toolpanel-ltone;
}

.layout-pf-alt.layout-pf-fixed body {
  padding-top: 48px;
}

.nav {
  & > li > a:hover {
    background: transparent;
  }
}

.nav-pf-vertical {
  transition: 0.2s;
  a {
    text-decoration: none;
  }
  &.nav-pf-right {
    left: auto;
    right: 0;
    .list-group-item {
      &.active {
        & > a:before {
          left: 0;
          right: auto;
        }
      }
    }
  }
  .list-group-item {
    &.active {
      & > a:before {
        left: auto;
        right: 0;
      }
    }
    & > a .fa {
      color: $toolpanel-contrast;
      fill: $toolpanel-contrast;
    }
    &.active > a .fa,
    &:hover > a .fa {
      fill: $main-color;
    }
    .router-link-exact-active,
    .router-link-active {
      background-color: $main-active-background-menu;
      border-right: 4px solid $main-color;
      color: $main-color-contrast;
      font-weight: 600;
      svg,
      .fa {
        fill: $main-color;
      }
    }
  }
  &.collapsed {
    .is-clicked {
      .nav-pf-secondary-nav {
        right: 75px;
      }
    }
  }
  .is-clicked {
    .nav-pf-secondary-nav {
      opacity: 1;
      visibility: visible;
      left: auto;
      right: 200px;
      width: 300px;
      top: 60px;
      .menu {
        width: auto;
      }
    }
  }
}

/* Override global & main & what not */

.form-control.disabled {
  background: $toolpanel-mtone;
}

.form-horizontal label {
  margin: 0;
}

.form-horizontal .onoffswitch {
  margin: 0;
  vertical-align: bottom;
}

.layout-pf .main-content {
  top: $navbar-pf-height;
}

.label--radio {
  position: relative;
  font-family: Arial, sans-serif;
  line-height: 135%;
  cursor: pointer;
}

.checkbox {
  position: relative;
  opacity: 0;
  cursor: pointer;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    z-index: 1;
    border: 2px solid red;
    border-top-style: none;
    border-right-style: none;
  }
  &:checked {
    &:before {
      transform: rotate(-45deg) scale(1, 1);
    }
  }
}

.breadcrumb {
  padding: 0;
  margin: 0;
}

fieldset {
  min-width: fit-content;
}

.popover-title {
  margin: 0;
  padding: 0;
  color: inherit;
  background: transparent;
}

table.dataTable thead {
  .sorting_desc,
  .sorting_asc {
    &:before {
      background: transparent;
    }
  }
}

.dataTables_wrapper {
  padding-bottom: 50px;
}

.table {
  & > tbody > tr > td {
    vertical-align: middle;
  }
  thead {
    background: $panel-ltone;
  }
}

.dataTables_header {
  background-color: $panel-background;
}

.dataTables_footer {
  background-color: $panel-background;
}

.dataTables_paginate {
  background: $panel-background;
  .pagination {
    > li {
      > span {
        color: $toolpanel-contrast;
        background: $toolpanel-background;
        border-color: $toolpanel-ltone $toolpanel-ltone $toolpanel-ltone;
      }
      &.disabled > span {
        color: $toolpanel-htone;
        background: $toolpanel-ltone;
        border-left-color: $toolpanel-htone;
        border-right-color: $toolpanel-htone;
      }
    }

    > li > a,
    > li > span {
      &:hover,
      &:focus {
        z-index: 2;
        color: $toolpanel-contrast;
        background-color: $toolpanel-htone;
        border-color: $toolpanel_ltone;
      }
    }
  }
  .pagination-input {
    .paginate_input {
      border: 1px solid $color-pf-black-300;
    }
  }
}

.layout-pf-fixed .navbar-pf-vertical {
  z-index: 1031;
}

.navbar-pf-vertical {
  .navbar-toggle {
    margin: 0;
    padding: 13px 15px;
  }
  .nav {
    .nav-item-iconic {
      padding: 11px 15px;
      line-height: 18px;
    }
  }
}

.navbar-pf-vertical {
  min-height: 35px;
  .navbar-brand {
    padding: 0px 0 0 0;
    margin: 0 0 0 5px;
    svg {
      height: 35px;
    }
  }
}

.navbar-collapse {
  padding-right: 10px;
  text-align: center;
}

.drawer-pf-title {
  background-color: $toolpanel-ltone;
}

.drawer-pf {
  background-color: $toolpanel-background;
  z-index: 5;
}

.drawer-pf-close,
.drawer-pf-toggle-expand,
.drawer-pf-loading {
  color: $toolpanel-contrast;
}

.panel {
  border: none;
  .panel-heading {
    display: flex;
    align-items: center;
    label {
      line-height: 1;
      margin-left: 5px;
    }
  }
}

.panel-group {
  .panel-title > a {
    color: $secondary-color-contrast;
  }

  .panel {
    color: $panel-contrast;
  }
}

.dropdown-kebab-pf {
  .dropdown-menu {
    margin-bottom: 50px;
  }
}

.btn-default {
  background: $secondary-color-htone-bis;
  border-color: transparent;
  color: $secondary-color-contrast;
  transition: 0.5s;
  &:hover,
  &:active:focus,
  &:focus {
    transition: 0.5s;
    background: $secondary-color-htone;
    border-color: transparent;
    color: $main-color-contrast;
  }
  &.disabled {
    color: $main-color-contrast !important;
    background: $secondary-color-htone-bis !important;
    opacity: 0.8;
  }
}

.btn-primary {
  position: relative;
  background: $main-color-vltone;
  color: $main-color-contrast;
  border: none;
  transition: 0.5s;
  &:hover,
  &:focus,
  &.dropdown-toggle:focus {
    color: $main-color-contrast;
    transition: 0.5s;
  }
  &.disabled {
    color: $main-color-contrast !important;
    background: $main-color !important;
    opacity: 0.8;
  }
}

.btn-primary[disabled] {
  background-color: $main-color-alpha40 !important;
  border-color: $panel-background-alpha50 !important;
  color: $dis-main-color-contrast !important;
}

.btn-danger {
  position: relative;
  background: $error-color;
  color: $main-color-contrast;
  border: none;
  transition: 0.5s;
  &:hover,
  &:focus,
  &.dropdown-toggle:focus {
    color: $main-color-contrast;
    transition: 0.5s;
  }
}

.btn-link {
  color: $main-color-vltone;
  &.dropdown-toggle {
    color: $main-color-contrast;
  }
}

.dropdown.dropdown-kebab-pf {
  .btn-link {
    color: $main-color-vltone;
    &.dropdown-toggle {
      color: $main-color-contrast;
    }
  }
}

li {
  list-style: none;
}

div:not(drawer-pf) {
  .card-pf-heading,
  .card-pf-heading-kebab {
    border-bottom: 1px solid $main-color-contrast;
  }
}

.badge {
  &.error-reject {
    background: $error-color;
  }
}

.items-list .zoom-DetailedSmall {
  .card-pf {
    .modeless-infos {
      bottom: calc(100% - 30px);
      right: calc(100% - 27px);
    }
  }
}

.card-pf-view-multi-select {
  cursor: pointer;
}

.items-list .card-pf {
  .modeless-infos {
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
  }
  .badge-container {
    width: 100%;
    padding: 0 10px;
  }
  .card-pf-body {
    position: relative;
    margin: 0;
    width: 100%;
    padding: 0;
    border: 3px solid transparent;
    transition: 0.3s;
    .card-pf-heading {
      margin: 0;
      padding: 0;
      border: none;
      display: content;
      .card-pf-title {
        margin: 0;
        .text_container {
          font-size: 14px;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
    &.checked {
      border: 3px solid $main-color;
      transition: 0.3s;
    }
    .card-pf-items {
      margin-top: 5px;
    }
    .bare {
      z-index: 1;
      position: relative;
      img {
        width: 100%;
      }
      .order-number {
        position: absolute;
        color: #222;
        right: 5px;
        bottom: 7px;
        font-size: 14px;
      }
    }
  }
  .card-pf-view-checkbox {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    input[type="checkbox"] {
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
  padding: 0;
  .card-pf-heading {
    color: $main-color-contrast;
  }
  .item-information {
    color: $dis-secondary-color-contrast;
    & > div > div {
      padding: 3px 0;
      font-size: 14px;
      .comment-details {
        height: fit-content;
        padding: 0;
        box-shadow: 0 0 0 transparent;
        border: none;
      }
      & > span {
        display: inline-block;
        padding-right: 10px;
      }
    }
    span {
      display: inline-block;
      padding-right: 10px;
    }
    .item-v {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 55%;
      margin: -5px 0;
      padding: 4px 0;
      line-height: 1;
      white-space: nowrap;
      display: inline-block;
      &:not(.red):not(.validation) {
        color: $main-color-contrast;
      }
    }
  }
  .card-pf-footer {
    background: transparent;
  }
}

.blank-slate-pf {
  background: $viewer-htone;
  border-color: $viewer-htone;

  p {
    color: $toolpanel-contrast;
    text-align: center;
  }

  h1 {
    color: $toolpanel-contrast;
    font-size: 28px;
    font-weight: 300;
    margin-top: 23px;
    margin-bottom: 11.5px;
    line-height: 1.1;
  }

  svg {
    fill: $toolpanel-contrast;
  }
}

.list-view-pf {
  .close-order {
    top: 5px;
    color: $main-color-contrast;
    opacity: 1;
  }
  .list-group-item {
    background: $toolpanel-ltone;
    border-color: $toolpanel-htone;
    color: $shade-h;
    &:first-child {
      border: 1px solid $toolpanel-htone;
    }
    &:hover {
      border-left-color: $toolpanel-htone;
      border-right-color: $toolpanel-htone;
    }
    &.disabled {
      cursor: not-allowed !important;

      .list-view-pf-actions > a.btn {
        color: $dis-main-color-contrast;
        background-color: $main-color-alpha40;
      }
    }
  }
}

.alert {
  color: $secondary-color;
}

#itemsDetailed {
  .card-pf-body {
    .img .production_item_name {
      display: none;
    }
    .production_item_info {
      position: absolute;
    }
  }
}

.toast-pf {
  width: 100%;
}

#notifContainer {
  .toast-pf {
    display: block;
  }
}

//.toast-pf {
//	background-color: $toolpanel-background;
//	border-color: $toolpanel-ltone;
//}

//.toast-pf > .pficon-info {
//	background-color: $main-color;
//}

.toast-pf button.close,
.toast-pf button.close:hover {
  background-color: transparent;
}

.toast-pf button {
  pointer-events: all;
}

[disabled].fake,
[readonly].fake {
  background-color: $panel-background;
  color: $panel-contrast;
}

#results-count > h5 {
  color: $text-color;
  position: unset;
  text-align: unset;
  width: unset;
}

.toolbar-pf {
  background-color: $viewer-htone;
}

.list-group-item-container .toolbar-pf {
  background-color: $toolpanel-ltone;
}

.toolbar-pf-results {
  h5 {
    color: $text-color;
    position: unset;
    text-align: unset;
    width: unset;
  }
  .label-group {
    background-color: lighten($label-info-bg, 20%);
    padding-right: 0.2em;
    .label {
      font-size: ($font-size-base - 3);
      margin-left: 0.6em;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.toolbar-pf-actions .filter-active {
  color: $main-color-bg-light;
  border-color: $main-color-bg-light;
}

.progress {
  &.progress-xxs {
    height: 2px;
    margin-bottom: 2px;
  }
}

.spinner-loader.progress {
  background-color: transparent;
}

.toolbar-pf-view-selector {
  display: flex;
  align-items: flex-start;
}
.loading-state .list-view-pf-body {
  flex-direction: column;
}

.list-view-pf.list-view-bordered {
  .list-group-item {
    border-top: 1px solid $table-border-color;
  }
}

.list-view-pf.list-view-condensed {
  .list-view-pf-actions {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .list-view-pf-main-info {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.list-view-pf-actions {
  a.btn {
    color: $main-color-contrast;

    &:hover {
      color: $main-color-contrast;
    }
  }
}

.table {
  tr.ui-sortable-handle {
    &.ui-sortable-helper {
      display: flex;
      .table-view-pf-actions:last-child {
        display: none;
      }
    }
    td.pf-grab-container {
      display: flex;
      align-items: center;
      padding: 5px 0;
    }
  }
}

span.pf-grab {
  content: "....";
  width: 10px;
  height: 20px;
  display: inline-block;
  cursor: move;
  cursor: grab;
  line-height: 5px;
  padding: 3px 4px;
  vertical-align: middle;
  margin-top: -1.1em;
  margin-right: 1em;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #cccccc;
  text-shadow: 1px 0 1px black;
}

span.pf-grab::after {
  content: ".. .. .. ..";
}

.pf-grab:active {
  cursor: grabbing;
}

.ui-sortable-helper {
  transform: rotate(2deg);
}

.table-hover .ui-sortable-helper {
  background: $table-bg-hover;
}

label.required-pf {
  position: relative;
  &:after {
    color: $error-color;
    position: absolute;
    top: 5px;
  }
}

.pf-expand {
  position: relative;
  &:not(.expanded) {
    .txt-h {
      display: none;
    }
  }
  &.expanded {
    .txt-s {
      display: none;
    }
  }
  .pf-expand-body {
    overflow: hidden;
    height: auto;
  }
  &:not(.expanded) {
    & > .pf-expand-body {
      max-height: 0px;
    }
  }
  &.expanded {
    & > .pf-toggle-expand > .fa-caret-right {
      transform: rotate(90deg);
    }
  }
  .pf-toggle-expand {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0;
    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 209px);
      height: 1px;
      right: 0;
      top: 50%;
      background: $dis-secondary-color;
    }
    i {
      margin: 0 5px;
      transition: 0.3s;
    }
  }
}

.dashboard-pf {
  .dashboard-pf-header {
    display: flex;
    justify-content: space-between;
    height: 45px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    .dashboard-pf-title-left {
      &.close-option {
        padding-left: 40px;
      }
    }
    .dashboard-pf-title-right {
      padding-right: 15px;
      display: flex;
      align-items: center;
      @media (max-width: 1024px) {
        align-items: baseline;
        flex-direction: column;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      height: auto;
      padding-left: 5px;
    }
  }
}

.p0 {
  padding: 0;
}

.size-inputs {
  border: 1px solid $toolpanel-htone;
  background: $panel-background;
  display: inline-block;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  input[type="number"] {
    padding: 0;
    border: 0;
    outline: 0;
    width: calc(33% - 7px);
    height: 29px;
    line-height: 39px;
    &:not(.control-label) {
      text-align: center;
    }
  }
  .separator {
    display: inline-block;
    width: 10px;
    color: $toolpanel-htone;
  }
  input.x-size-input,
  input.y-size-input {
    margin-right: 0px;
  }
}

.no-outline:focus {
  outline: transparent;
}

.table-treegrid .selected {
  background-color: $table-bg-hover;
  color: $tertiary-color;
}
.inline-edit {
  position: relative;
  display: inline-block;
  margin: 0 20px;
  .fa.fa-pencil {
    position: absolute;
    top: calc(50% - 8px);
    right: 0;
  }
  input[type="tel"],
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  input[type="search"],
  button,
  textarea,
  select {
    background: transparent;
    border: 1px solid transparent;
    margin-left: -5px;
    line-height: 1;
    &:hover {
      border: 1px solid $panel-ltone;
      background: $panel-background;
    }
    &:focus {
      background: $panel-background;
      & + .fa.fa-pencil {
        display: none;
      }
    }
  }
  &:hover,
  &:focus {
    input[type="tel"],
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="number"],
    input[type="date"],
    input[type="search"],
    button,
    textarea,
    select {
      background: $panel-background;
    }
    .fa.fa-pencil {
      display: none;
    }
  }
}

.flag-container {
  color: $tertiary-color;
  i {
    margin-right: 5px;
  }
  &.row_amt {
    margin: 0;
  }
}

.required-pf-hint {
  color: $toolpanel-htone;
}

.modal {
  .wizard-pf-body {
    background: $modal-content-bg;
  }
  .wizard-pf-steps-indicator {
    background: $modal-content-bg;

    li {
      a {
        color: $toolpanel-contrast;
      }
    }

    .wizard-pf-step-number {
      background: $modal-content-bg;
    }

    .active {
      .wizard-pf-step-number {
        border-color: $main-color;
        background: $main-color;
      }
    }
  }
  .wizard-pf-footer {
    background: $modal-content-bg;
  }
  .seperator-right {
    border-right: 1px solid $secondary-color-contrast;
  }
}

textarea.form-control {
  max-width: 100%;
  min-width: 100%;
}

.list-group-item {
  color: $toolpanel-contrast;
}

.list-view-pf-left {
  .pficon.list-view-pf-icon-main,
  .fa.list-view-pf-icon-main {
    background-color: fade-out($main-color, 0.9);
    color: $main-color;
  }

  .pficon.list-view-pf-icon-warning,
  .fa.list-view-pf-icon-warning {
    background-color: fade-out($warning-color, 0.9);
    color: $warning-color;
  }

  .pficon.list-view-pf-icon-tertiary,
  .fa.list-view-pf-icon-tertiary {
    background-color: fade-out($tertiary-color-ltone, 0.9);
    color: $tertiary-color-ltone;
  }

  .pficon.list-view-pf-icon-success,
  .fa.list-view-pf-icon-success {
    background-color: fade-out($success-color, 0.9);
    color: $success-color;

    .blank-slate-pf h1 {
      color: $toolpanel-contrast;
    }
  }

  .pficon.list-view-pf-icon-danger,
  .fa.list-view-pf-icon-danger {
    background-color: fade-out($error-color, 0.9);
    color: $error-color;
  }

  .spinner-sm {
    height: 26px;
    line-height: 26px;
    width: 26px;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.sub-title {
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 0.9em;
}

.welcome_message_cont {
  background: $panel-background;
  border-radius: 3px;
  border: 1px solid $panel-ltone;
  color: $panel-contrast;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  h1 {
    font-size: larger;
    font-weight: 700;
    margin: 5px;
  }
  h2 {
    font-weight: 700;
    margin: 15px 0;
    &:first-of-type {
      margin-top: 5px;
    }
  }
  p {
    margin: 10px 0;
    text-align: left;
  }
  ul {
    text-align: left;
    margin-left: 20px;
  }
  li {
    margin-left: 15px;
    text-align: left;
    list-style-position: outside;
    list-style-type: disc;
  }
}

.close-button {
  border-radius: 3px;
  background-color: #888;
  padding: 1px 5px 3px;
  border: 1px solid #555;
  margin-right: 10px;
  position: relative;
  float: right;
  cursor: pointer;
}

svg.icon-close-project {
  height: 10px;
  width: 12px;
}

.alert-dismissable {
  .badge {
    position: absolute;
    right: 40px;
    top: 5px;
  }
}

.card-pf .overflow-ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.card-pf .no-wrap {
  white-space: nowrap;
}

.hint-block-pf {
  button.close {
    background-color: transparent;
  }
}

.modeless-slide-enter-active,
.modeless-slide-leave-active {
  transition: margin-right 0.1s;
}

.modeless-slide-enter,
.modeless-slide-leave-to {
  margin-right: -450px;
}

.pagination {
  > li {
    > a,
    > span {
      color: $panel-contrast;
      background-color: $pagination-bg;
      border-color: $panel-mtone;

      &:hover,
      &:focus {
        color: $panel-contrast;
        background-color: $panel-htone;
        border-color: $panel-mtone;
      }
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      color: $panel-contrast;
      background-color: $panel-ltone;
      border-color: $panel-mtone;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: $panel-htone;
      background-color: $pagination-disabled-bg;
      border-color: $panel-mtone;
    }
  }
}

.modal-backdrop.drop-fade-enter-active,
.modal-backdrop.drop-fade-leave-active,
.modal.drop-fade-enter-active,
.modal.drop-fade-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.modal.drop-fade-enter,
.modal.drop-fade-leave-to {
  transform: translateY(-100%);
}

.modal-backdrop.drop-fade-enter,
.modal-backdrop.drop-fade-leave-to,
.modal.drop-fade-enter,
.modal.drop-fade-leave-to {
  opacity: 0;
}

.dropdown-kebab-pf:not(body) .caret {
  height: 1em;
  vertical-align: inherit;
  width: 3px;
}

.dropdown-kebab-pf:not(body) .caret:before {
  font-family: FontAwesome;
  content: "\F142";
  line-height: 1;
}

.pf-loading-state-pf {
  @extend .loading-state-pf;
}

.pf-loading-state-pf-lg {
  @extend .loading-state-pf-lg;
}

.card-pf-view {
  .card-pf-top-element .card-pf-icon-rect {
    @extend .card-pf-icon-circle;
    border-radius: 0;

    img {
      width: 100%;
    }

    &.card-pf-icon-loading {
      position: relative;

      .loading-state-pf {
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

.bs-searchbox .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: $panel-contrast;
}

.list-view-pf .list-group-item-heading {
  @media (min-width: $screen-md-min) {
    font-size: $font-size-large;
  }
}

.help-block.help-block-error {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  fill: $error-color;
  color: $error-color;
}

.help-block.help-block-warning {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  fill: $warning-color;
  color: $warning-color;
}

.list-group-item-container .help-block {
  margin-left: 50px;
}

.margin-default {
  margin: 0;
}
